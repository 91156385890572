import { maxLength } from 'vuelidate/lib/validators';
import { CellContentType } from '../../../../shared/components/table/models/cellContent.model';
import { TableHeaders } from '../../../../shared/components/table/models/tableHeaders.model';

export const ConfigurationSSLTableHeaders: TableHeaders[] = [
  {
    key: 'name',
    label: 'SSL',
    content: {
      maxLength: 36,
      type: CellContentType.Text
    }
  },
  {
    key: 'id',
    label: 'ABBR',
    content: {
      maxLength: 5,
      type: CellContentType.Text
    },
    disabledCondition: ({ newItem }) => !newItem
  },
  {
    key: 'save',
    label: '',
    disabledCondition: ({ name, id }) => !name?.length || !id?.length,
    content: {
      type: CellContentType.Button,
      label: 'Save'
    }
  },
  {
    key: 'active',
    label: ' ',
    content: {
      type: CellContentType.Switcher
    }
  }
];
