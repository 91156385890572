import { render, staticRenderFns } from "./ConfigurationType.vue?vue&type=template&id=1a230ddc&scoped=true&"
import script from "./ConfigurationType.vue?vue&type=script&lang=ts&"
export * from "./ConfigurationType.vue?vue&type=script&lang=ts&"
import style0 from "./ConfigurationType.vue?vue&type=style&index=0&id=1a230ddc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1a230ddc",
  null
  
)

export default component.exports