






































import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';
import { ConfigurationSizeTableHeaders } from './model/ConfigurationSize.model';
import { BaseConfigClass } from './BaseConfig.class';
import { ConfigType } from './model/Config.model';

@Component({
  components: { TmsTable }
})
export default class ConfigurationSize extends Vue {
  columns: TableHeaders[] = ConfigurationSizeTableHeaders;
  config = new BaseConfigClass(ConfigType.SIZE);

  created() {
    this.config.getData();
  }
}
