






































import { Component, Vue } from 'vue-property-decorator';
import TmsTable from '@/shared/components/table/TmsTable.vue';
import { TableHeaders } from '@/shared/components/table/models';
import { ConfigurationSSLTableHeaders } from './model/ConfigurationSSL.model';
import { BaseConfigClass } from '@/pages/Admin/Configuration/BaseConfig.class';
import { ConfigType } from '@/pages/Admin/Configuration/model/Config.model';

@Component({
  components: { TmsTable }
})
export default class ConfigurationSSL extends Vue {
  columns: TableHeaders[] = ConfigurationSSLTableHeaders;
  config = new BaseConfigClass(ConfigType.SSL);

  created() {
    this.config.getData();
  }
}
