












import { Component, Vue } from 'vue-property-decorator';
import ConfigurationDivisions from './ConfigurationDivisions.vue';
import ConfigurationPool from './ConfigurationPool.vue';
import ConfigurationSize from './ConfigurationSize.vue';
import ConfigurationSSL from './ConfigurationSSL.vue';
import ConfigurationType from './ConfigurationType.vue';

@Component({
  components: {
    ConfigurationDivisions,
    ConfigurationPool,
    ConfigurationSize,
    ConfigurationSSL,
    ConfigurationType
  }
})
export default class Inventory extends Vue {}
