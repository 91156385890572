import { ToastHelper } from '@/utils/toast.util';
import { AdminConfService } from '../../../shared/services/admin-conf/admin-conf.service';
import { ConfigType, StatusOptions } from './model/Config.model';
import store from '@/store/index';
export class BaseConfigClass {
  rows = [];
  adminService = AdminConfService;
  type: ConfigType;
  loading = true;
  statusOptions = StatusOptions;
  status = null;

  constructor(type: ConfigType) {
    this.type = type;

    store.watch(
      (state: any) => state.division.division,
      () => this.getData()
    );
  }

  async getData() {
    const data = await this.adminService.getData(this.type);
    this.rows = [...data.sort(i => (i.active ? -1 : 1))];
    this.rows = data;
    this.loading = false;
  }

  newItem() {
    const data = { newItem: true };

    this.rows.push(data);
  }

  onInputChange({ field, data }) {
    switch (field) {
      case 'active':
        this.changeActive(data);
        break;
      default:
        break;
    }
  }

  onAction({ key, data }: { key: string; data: any }) {
    switch (key) {
      case 'save':
        this.save(data);
        break;
      default:
        break;
    }
  }

  async save(item) {
    const data = { ...item };
    delete data._rowId;
    delete data.number;

    try {
      if (!data.newItem) {
        await this.adminService.saveItem(this.type, data.id, data);
      } else {
        delete data.newItem;

        if (!data.id) {
          data.id = data.name;
        }
        await this.adminService.createItem(this.type, data);
      }

      ToastHelper.show(
        `Admin - ${this.type}`,
        'Save successful',
        5000,
        'success'
      );
    } catch (err) {
      let errColumn;
      switch (err.response.data.field) {
        case 'ID':
          errColumn = 'ABBR';
          break;
        case 'NAME':
          if (err.response.data.entity === 'CONTSIZE') {
            errColumn = 'CONTAINER';
          } else if (err.response.data.entity === 'CONTTYPE') {
            errColumn = 'TYPE';
          } else {
            errColumn = err.response.data.entity;
          }
          break;
        default:
          errColumn = err.response.data.field;
          break;
      }
      const errMessage = `This ${err.response.data.value} is duplicated. Please insert the different ${errColumn} for this one.`;

      ToastHelper.show(
        `Admin - ${this.type}`.toUpperCase(),
        errMessage || err.message,
        5000,
        'danger'
      );
    }
  }

  async changeActive({ id, active }) {
    // Skip changes for the new items
    if (!id) {
      return;
    }

    try {
      await this.adminService.updateActive(this.type, id, { active });
    } catch (err) {
      ToastHelper.show(this.type, err.message, 5000, 'danger');
    }
  }
}
